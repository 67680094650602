<template>
  <div>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Направление</th>
        <th scope="col">Мин. вх.</th>
        <th scope="col">Макс. вх.</th>
        <th scope="col">Мин. исх.</th>
        <th scope="col">Макс. исх.</th>
        <th scope="col">Комиссия фикс.</th>
        <th scope="col">Комиссия, %</th>
        <th scope="col">Активно</th>
      </tr>
      <tr>
        <th scope="col"><input style="width: 100%" disabled/></th>
        <th scope="col"><input v-model="filter.direction" style="width: 100%"/></th>
        <th scope="col"><input style="width: 100%" disabled/></th>
        <th scope="col"><input style="width: 100%" disabled/></th>
        <th scope="col"><input style="width: 100%" disabled/></th>
        <th scope="col"><input style="width: 100%" disabled/></th>
        <th scope="col"><input style="width: 100%" disabled/></th>
        <th scope="col"><input style="width: 100%" disabled/></th>
        <th scope="col"><input style="width: 100%" disabled/></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="pair in pairFilter" v-bind:key="pair.id">
        <td>{{ pair.id }}</td>
        <td>{{ pair.src.name }} --> {{ pair.dst.name }}</td>
        <td><input v-model="pair.minSrc" style="width: 100px"/></td>
        <td><input v-model="pair.maxSrc" style="width: 100px"/></td>
        <td><input v-model="pair.minDst" style="width: 100px"/></td>
        <td><input v-model="pair.maxDst" style="width: 100px"/></td>
        <td><input v-model="pair.fixedFee" style="width: 100px"/></td>
        <td><input :value="pair.percentFee | percent" type="number" step="1"
                   @input="pair.percentFee = fraction($event.target.value)" style="width: 100px"/></td>
        <td><input v-model="pair.enabled" class="form-check-input" type="checkbox"></td>
      </tr>
      </tbody>
    </table>
    <button :disabled="saveButtonDisabled" class="btn btn-success mt-4" type="button" v-on:click="save">Сохранить
    </button>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Pairs',

  data: function () {
    return {
      saveButtonDisabled: false,
      filter: {
        direction: ''
      }
    }
  },

  computed: {
    ...mapGetters(['allPairs']),

    pairFilter: function () {
      return this.allPairs.filter(pair => {
        if (this.filter.direction) {
          let direction = this.filter.direction.toLowerCase();
          return pair.src.name.toLowerCase().includes(direction)
              || pair.dst.name.toLowerCase().includes(direction)
        } else {
          return true
        }
      })
    },
  },

  methods: {
    ...mapActions(["fetchPairs", "updatePairs"]),
    save: async function () {

      let otp = prompt("OTP");

      console.log(otp);
      this.saveButtonDisabled = true
      await this.updatePairs(otp)
      this.saveButtonDisabled = false
    },

    fraction: function (percent) {
      if (percent === 0) return 0
      if (percent == "-") return;
      return (percent / 100);
    }
  },

  filters: {
    percent: function (fraction) {
      return (fraction * 100).toFixed(2);
    }
  },

  mounted() {
    this.fetchPairs()
  }
}
</script>